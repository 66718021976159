import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { useIntl } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'

import { JsonLd } from 'gatsby-plugin-next-seo'

import GeneralInfoText from '@objects/generalInfoText'
import TopicArticleTeaser from '@objects/topicArticleTeaser'
import Stage from '@components/stage'
import DialogTeaserCard from '@components/dialogTeaserCard'

const useStyles = makeStyles((theme) => ({
  infoCopy: {
    margin: theme.spacing(-25, 0, -25),
    padding: theme.spacing(20),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(20, 20),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(20, 50),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(20, 80),
    },
    [theme.breakpoints.up('desktop')]: {
      padding: theme.spacing(20, 150),
    },
  },
  isLarge: {
    fontSize: 'x-large',
    margin: theme.spacing(-5, 0, -30),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(35),
    },
  },
  teaserList: {
    marginBottom: theme.spacing(20),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(34),
    },
  },
}))

function Topic({ data, pageContext, modules }) {
  const intl = useIntl()
  const classes = useStyles()

  const topicData = get(data, 'contentfulTopicCategory')

  const {
    stageCopy,
    title: stageTitle,
    stageImage,
    stageImageWithFocalpoint,
  } = topicData

  const articles = get(data, 'contentfulTopicCategory.nextLevel').map(
    (article) => {
      return {
        type: article.__typename,
        id: article.id,
        headline: article.title,
        copy: article.childContentfulStaticFieldValueTextNode
          ? article.childContentfulStaticFieldValueTextNode?.childMarkdownRemark
              .rawMarkdownBody
          : article.stageCopy
          ? article.stageCopy?.childMarkdownRemark?.html
          : article.teaserCopy?.childMarkdownRemark?.html,
        image: article.teaserImageWithFocalpoint
          ? article.teaserImageWithFocalpoint.image.fluid
          : article.teaserImage?.fluid,
        imageAlt: article.teaserImageWithFocalpoint
          ? article.teaserImageWithFocalpoint.image.description
          : article.teaserImage?.description,
        focalPoint: article.teaserImageWithFocalpoint?.focalPoint,
        slug: `${intl.formatMessage({
          id: 'inform.path',
        })}${article.stageCopy ? '' : pageContext.slug + '/'}${article.slug}/`,
        linktext: intl.formatMessage({ id: 'topic.learn.more' }),
        key: article.key,
      }
    }
  )

  function renderArticles() {
    let reversed = false
    let isDecorated = false

    let unfilteredArticles = []
    unfilteredArticles = articles.filter((article) =>
      article.type === 'ContentfulStaticField'
        ? !!article.copy
        : !!article.headline && !!article.image && !!article.copy
    )

    let filteredArticles = []
    filteredArticles = articles.filter(
      (article) => !!article.headline && !!article.image && !!article.copy
    )

    let mappedArticles = filteredArticles.map((article, i) => {
      isDecorated = i % 2 ? true : false
      if (i % 2) {
        reversed = !reversed
      }
      return {
        reversed: reversed,
        isDecorated: isDecorated,
        articleindex: i,
        remainder: i % 2,
        article,
      }
    })

    unfilteredArticles.forEach(function (item) {
      if (item.type === 'ContentfulStaticField') {
        mappedArticles.splice(unfilteredArticles.indexOf(item), 0, item)
      }
    })

    return mappedArticles.map((article) => {
      const isLargeCopy = article.key?.includes('large')

      return article.type === 'ContentfulStaticField' ? (
        <GeneralInfoText markdown={article.copy} isLargeCopy={isLargeCopy} />
      ) : (
        <TopicArticleTeaser
          key={article.article.id}
          image={article.article.image}
          imageAlt={article.article.imageAlt}
          focalPoint={article.article.focalPoint}
          headline={article.article.headline}
          copy={article.article.copy}
          slug={article.article.slug}
          linktext={article.article.linktext}
          decorated={article.isDecorated}
          reversed={article.reversed}
        />
      )
    })
  }

  return (
    <React.Fragment>
      <JsonLd
        json={{
          '@context': 'https://schema.org',
          '@type': 'CollectionPage',
        }}
      />
      <Stage
        image={
          stageImageWithFocalpoint ? stageImageWithFocalpoint.image : stageImage
        }
        focalPoint={stageImageWithFocalpoint?.focalPoint}
        title={stageTitle}
        ariaLabel={stageTitle}
        copy={stageCopy?.stageCopy}
        noMargin={true}
      />

      <div className={classes.teaserList}>{renderArticles()}</div>

      {intl.locale === 'de' && (
        <DialogTeaserCard
          headline={intl.formatMessage({ id: 'teaser.onlinedialog.headline' })}
          copy={intl.formatMessage({ id: 'teaser.onlinedialog.copy' })}
          buttons={[
            {
              label: intl.formatMessage({ id: 'button.onlinedialog' }),
              link: intl.formatMessage({ id: 'dialog.path' }),
            },
            {
              label: intl.formatMessage({ id: 'button.createArticle' }),
              color: 'outline',
              create: true,
            },
          ]}
        />
      )}
    </React.Fragment>
  )
}

Topic.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
  modules: PropTypes.array,
}

export default Topic

export const pageQuery = graphql`
  query TopicPageQuery(
    $slug: String!
    $locale: String!
    $contentful_id: String!
  ) {
    SlugByContentfulId: contentfulTopicCategory(
      contentful_id: { eq: $contentful_id }
      node_locale: { ne: $locale }
    ) {
      slug
      node_locale
      fields {
        fullPath
      }
    }
    contentfulTopicCategory(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      id
      node_locale
      fields {
        fullPath
      }
      title
      naviTitle
      stageCopy {
        stageCopy
      }
      stageImage {
        fluid(maxWidth: 2000, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        description
      }
      stageImageWithFocalpoint {
        image {
          fluid(maxWidth: 2000, quality: 90) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          description
        }
        focalPoint
      }
      nextLevel {
        ... on ContentfulArticle {
          id
          title
          internal {
            type
          }
          teaserImage {
            fluid(maxWidth: 1000, quality: 90) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            description
          }
          teaserImageWithFocalpoint {
            image {
              fluid(maxWidth: 704, quality: 90) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
              description
            }
            focalPoint
          }
          teaserCopy {
            childMarkdownRemark {
              html
            }
          }
          slug
        }
        ... on ContentfulTopicCategory {
          id
          title
          internal {
            type
          }
          teaserImage {
            fluid(maxWidth: 1000, quality: 90) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            description
          }
          teaserImageWithFocalpoint {
            image {
              fluid(maxWidth: 1000, quality: 90) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
              description
            }
            focalPoint
          }
          stageCopy {
            childMarkdownRemark {
              html
            }
          }
          slug
        }
        ... on ContentfulStaticField {
          id
          key
          childContentfulStaticFieldValueTextNode {
            childMarkdownRemark {
              html
              rawMarkdownBody
            }
            value
          }
          internal {
            type
          }
        }
      }
    }
  }
`
