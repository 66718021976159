import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'

import { Link } from 'gatsby'

import ParallaxImage from '@objects/parallaxImage'
import Headline from '@objects/headline'
import Copy from '@objects/copy'
import Button from '@objects/button'
import { focalPointsTable } from '@tools/focalPoints'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.container.lg,
    padding: theme.spacing(10, 4),
    margin: '0 auto',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(20, 4),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(25, 4),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(30, 4),
    },
    '&:last-child': {
      paddingBottom: 0,
      [theme.breakpoints.up('lg')]: {
        paddingBottom: theme.spacing(20),
      },
    },
  },
  outer: {
    overflow: 'hidden',
    padding: theme.spacing(20, 0),
    margin: theme.spacing(-20, 0),
  },
  decorated: {
    backgroundColor: theme.palette.background.grey,
    '& $root': {
      position: 'relative',
      padding: theme.spacing(10, 4),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(15, 4),
      },
      [theme.breakpoints.up('xl')]: {
        padding: theme.spacing(20, 4),
      },
    },
  },
  illu: {
    position: 'absolute',
    zIndex: 0,
    width: '100px',
    [theme.breakpoints.up('sm')]: {
      width: '120px',
    },
    [theme.breakpoints.up('md')]: {
      width: '160px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '240px',
    },
    [theme.breakpoints.up('xl')]: {
      width: '300px',
    },
  },
  illuTop: {
    top: -theme.spacing(8),
    right: 0,
    transform: 'translateX(35%)',
    [theme.breakpoints.up('lg')]: {
      top: -theme.spacing(15),
      transform: 'translateX(42%)',
    },
    [theme.breakpoints.up('xl')]: {
      top: -theme.spacing(20),
      transform: 'translateX(78%)',
    },
  },
  illuGrey: {
    transform: 'translateX(25%)',
    [theme.breakpoints.up('md')]: {
      transform: 'translateX(20%)',
    },
    [theme.breakpoints.up('lg')]: {
      transform: 'translateX(30%)',
    },
    [theme.breakpoints.up('xl')]: {
      transform: 'translateX(63%)',
    },
  },
  illuBottom: {
    bottom: -theme.spacing(8),
    left: 0,
    transform: 'rotate(180deg) translateX(35%)',
    [theme.breakpoints.up('lg')]: {
      bottom: -theme.spacing(15),
      transform: 'rotate(180deg) translateX(42%)',
    },
    [theme.breakpoints.up('xl')]: {
      bottom: -theme.spacing(20),
      transform: 'rotate(180deg) translateX(78%)',
    },
    '&$illuGrey': {
      transform: 'rotate(180deg) translateX(25%)',
      [theme.breakpoints.up('lg')]: {
        transform: 'rotate(180deg) translateX(30%)',
      },
      [theme.breakpoints.up('xl')]: {
        transform: 'rotate(180deg) translateX(63%)',
      },
    },
  },
  content: {
    position: 'relative',
    zIndex: 1,
  },
  textblock: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(8),
    },
  },
  reversed: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 0,
      paddingRight: theme.spacing(8),
    },
  },
  headline: {
    marginBottom: theme.spacing(5),
  },
  copy: {
    ...theme.typography.teaser,
    marginBottom: theme.spacing(5),
  },
}))

function TopicArticleTeaser({
  className,
  slug,
  image,
  focalPoint,
  imageAlt,
  headline,
  copy,
  linktext,
  decorated,
  reversed,
}) {
  const classes = useStyles()

  function Wrapper({ children }) {
    if (decorated) {
      return (
        <div className={classes.outer}>
          <div className={clsx(className, classes.decorated)}>
            <div className={classes.root}>
              {children}
              {renderIllu('Top', reversed ? 'grey' : 'colored')}
              {renderIllu('Bottom', reversed ? 'colored' : 'grey')}
            </div>
          </div>
        </div>
      )
    } else {
      return <div className={clsx(classes.root, className)}>{children}</div>
    }
  }

  function renderIllu(position, color) {
    return (
      <img
        className={clsx(classes.illu, classes[`illu${position}`], {
          [classes.illuGrey]: color === 'grey',
        })}
        src={`/img/illustration/illustration_${color}_diagonal.svg`}
        alt=""
      />
    )
  }

  return (
    <Wrapper>
      <Grid
        container
        spacing={8}
        justify="center"
        alignItems="center"
        direction={reversed ? 'row-reverse' : 'row'}
        className={classes.content}
      >
        {image && (
          <Grid
            item
            xs={10}
            md={decorated ? 6 : 4}
            xl={decorated ? 7 : 4}
            component={Link}
            to={slug}
          >
            <ParallaxImage
              className={classes.image}
              image={image}
              scale={1.2}
              alt={imageAlt}
              focalPoint={focalPoint}
            />
          </Grid>
        )}
        <Grid item xs={10} md={decorated ? 4 : 6} xl={decorated ? 5 : 6}>
          <div
            className={clsx(classes.textblock, {
              [classes.reversed]: reversed,
            })}
          >
            <Link data-track-content data-tracking-id={slug} to={slug}>
              <Headline level={2} className={classes.headline}>
                {headline}
              </Headline>
            </Link>
            {copy && <Copy className={classes.copy} html={copy} />}
            {linktext && (
              <Button type="text" to={slug}>
                {linktext}
              </Button>
            )}
          </div>
        </Grid>
      </Grid>
    </Wrapper>
  )
}

TopicArticleTeaser.propTypes = {
  className: PropTypes.string,
  slug: PropTypes.string.isRequired,
  image: PropTypes.object,
  focalPoint: PropTypes.oneOf(Object.keys(focalPointsTable)),
  imageAlt: PropTypes.string,
  headline: PropTypes.string.isRequired,
  copy: PropTypes.string,
  linktext: PropTypes.string,
  decorated: PropTypes.bool,
  reversed: PropTypes.bool,
}
export default TopicArticleTeaser
