import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Headline from '@objects/headline'
import Copy from '@objects/copy'
import Icon from '@objects/icon'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  infoCopy: {
    position: 'relative',
    margin: theme.spacing(-6.25, 5, -6.25),
    padding: theme.spacing(5, 1),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(5, 11.25),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(5, 15),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(10, 20),
    },
    [theme.breakpoints.up('desktop')]: {
      padding: theme.spacing(10, 100),
    },
  },
  isLarge: {
    fontSize: 'x-large',
    margin: theme.spacing(-1.25, 5, -7.5),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(8.75),
    },
  },
}))

function GeneralInfoText({ markdown, isLargeCopy }) {
  const classes = useStyles()

  return (
    <div
      className={clsx(
        classes.infoCopy,
        isLargeCopy === true ? classes.isLarge : '',
        classes.infoCopy
      )}
    >
      <Copy markdown={markdown} />
    </div>
  )
}

GeneralInfoText.propTypes = {
  className: PropTypes.string,
  copy: PropTypes.array,
  markdown: PropTypes.string,
  isLargeCopy: PropTypes.bool,
}

export default GeneralInfoText
