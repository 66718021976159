import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
import Card from '@objects/card'
import Headline from '@objects/headline'
import Copy from '@objects/copy'
import Button from '@objects/button'
import CreateContentButton from '@objects/button/createContentButton'

const useStyles = makeStyles((theme) => ({
  dialogTeaserCardRoot: {
    marginBottom: theme.spacing(20),
    marginLeft: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(35),
      marginLeft: 0,
    },
  },
  copy: {
    ...theme.typography.teaser,
    marginBottom: theme.spacing(10),
  },
  button: {
    marginTop: theme.spacing(4),
    '&:not(:last-child)': {
      marginRight: theme.spacing(8),
    },
  },
}))

function DialogTeaserCard({ headline, copy, buttons }) {
  const intl = useIntl()
  const classes = useStyles()

  return (
    <div className={classes.dialogTeaserCardRoot} role="region" aria-label={
      intl.formatMessage({ id: 'arialabel.dialogTeaserCard' })
    }>
      <Card barOrientation={'left'} bar={'grey'} bind={'right'}>
        <Headline level={2}>{headline}</Headline>
        <Copy className={classes.copy}>{copy}</Copy>
        <div className={classes.buttons}>
          {buttons.map((button) => {
            if (button.create) {
              return (
                <CreateContentButton
                  key={button.label}
                  className={classes.button}
                  type={'secondary'}
                  color={button.color || 'red'}
                  aria-label={button.label}
                >
                  {button.label}
                </CreateContentButton>
              )
            } else {
              return (
                <Button
                  key={button.label}
                  className={classes.button}
                  color={button.color || 'red'}
                  link={button?.link}
                  href={button?.href}
                  type={'secondary'}
                  aria-label={button.label}
                >
                  {button.label}
                </Button>
              )
            }
          })}
        </div>
      </Card>
    </div>
  )
}

DialogTeaserCard.propTypes = {
  headline: PropTypes.string,
  copy: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      link: PropTypes.string,
      href: PropTypes.string,
      color: PropTypes.string,
    })
  ),
}

export default DialogTeaserCard
